<template>
  <div class="all_main_wrap">
    <!-- 头部 第一步页面 -->
    <div class="all_header_box">
      <div class="sider_left">
        <!-- 面包屑 -->
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/Certificate/Examination"
            >考试证书 /
          </router-link>
          <span class="crumbs_item crumbs_last">考生信息</span>
        </span>
      </div>
    </div>
    <!-- 步骤条 -->
    <div class="steps_box">
      <div class="steps_center">
        <div>
          <span class="num num-active">1</span>
          <span class="txt txt-active">考生信息</span>
        </div>
        <span class="arrow arrow-active"></span>
        <div>
          <span class="num">2</span>
          <span class="txt">证书生成</span>
        </div>
        <span class="arrow"></span>
        <div>
          <span class="num">3</span>
          <span class="txt">证书物流</span>
        </div>
      </div>
    </div>
    <!-- 搜索条件 -->
    <div class="query_box">
      <div class="side-left">
        <span>{{ recordId ? "已选场次" : "选择场次：" }}</span>
        <p>
          <a-input
            v-model="strings"
            :readonly="true"
            @click="recordId ? (EchoExamVisible = true) : (ExamVisible = true)"
            :placeholder="recordId ? '查看已选场次' : '请选择考试场次'"
          />
          <a-icon type="caret-down" class="icon" />
        </p>
      </div>
      <div class="side-right">
        <a-button type="primary" class="btn" @click="onAddStudent()" v-hasPermi="['certificates:examineecertif:add']"
          >新建考生</a-button
        >
        <a-button class="all_boder_btn btn" @click="deleteitem()" v-hasPermi="['certificates:examineecertif:delete']"
          >删除考生</a-button
        >
      </div>
    </div>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :row-selection="{
          selectedRowKeys: selectedRowKey,
          onChange: select,
          onSelectAll: selectAll,
          columnTitle: '选择',
        }"
        :rowKey="(item) => item.subId"
        :columns="columns"
        :data-source="tableData"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="footer">
          <a-checkbox
            @change="onChange"
            :checked="isChecked"
            class="allSellect"
          >
            全选
          </a-checkbox>
        </template>
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
           {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <!-- 证书模板 -->
        <template slot="operation" slot-scope="text, record">
          <div class="btn_router_link">
            <a v-hasPermi="['exam:signup:update']" @click="editshow(record)">编辑</a>
            <span v-hasPermi="['certificates:examineecertif:delete']">|</span>
            <a v-hasPermi="['certificates:examineecertif:delete']" @click="deleteExaminee(record.subId)">删除</a>
          </div>
        </template>
      </a-table>
    </div>
    <div class="foot_btn">
      <a-button type="primary" class="btn" @click="$router.go(-1)"
        >暂存</a-button
      >
      <a-button type="primary" class="btn" @click="goPage">下一步</a-button>
    </div>
    <!-- 关联考试弹窗 -->

    <a-modal
      @ok="getData()"
      v-model="ExamVisible"
      title="选择场次"
      okText="确认"
      :confirmLoading="loading"
      width="733px"
    >
      <div class="modalDiv">
        <!-- <a-select style="width:200px;margin-bottom:20px" placeholder="请选择考试名称" allowClear>
          <a-icon slot="suffixIcon" type="caret-down" />
        </a-select> -->
        <a-table
          class="table-template"
          :rowKey="(item) => item.timeId"
          :columns="ExamColumns"
          :data-source="testData"
          @change="onPages"
          :pagination="{
            total: totals,
            current: pageNumbers,
            defaultPageSize: pageSizes,
            showSizeChanger: true,
            showTotal: function (total, range) {
              return `共${totals}条`;
            },
          }"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: selects,
            onSelectAll: selectAlls,
            columnTitle: '选择',
          }"
        >
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center">
              {{ i + 1 }}
            </div>
          </template>
        </a-table>
      </div>
    </a-modal>

    <!-- 回显场次 -->
    <a-modal
      @ok="EchoExamVisible = false"
      v-model="EchoExamVisible"
      title="已选场次"
      okText="确认"
      width="500px"
    >
      <div class="modalDiv">
        <a-table
          class="table-template"
          :rowKey="(item) => item.timeId"
          :columns="EchoExamColumns"
          :data-source="testData"
          @change="onPages"
          :pagination="{
            total: totals,
            current: pageNumbers,
            defaultPageSize: pageSizes,
            showSizeChanger: true,
            showTotal: function (total, range) {
              return `共${totals}条`;
            },
          }"
        >
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center">
              {{ i + 1 }}
            </div>
          </template>
        </a-table>
      </div>
    </a-modal>
    <!-- 新建考生 -->
    <a-modal
      v-model="newVisible"
      :confirmLoading="loading"
      title="新建考生"
      okText="确认"
      width="533px"
    >
      <div class="all_content_box">
        <div class="line-item">
          <span class="all_left_name">姓名</span>
          <a-input
            class="right"
            v-model="formObJ.name"
            placeholder="请输入考生姓名"
          />
        </div>
        <div class="line-item">
          <span class="all_left_name">身份证号：</span>
          <a-input
            class="right"
            v-model="formObJ.idcard"
            placeholder="请输入考生身份证号"
          />
        </div>
        <a-button type="primary" class="btn" :loading="PreventLoad" @click="getExaminee()"
          >添加</a-button
        >
      </div>
      <template #footer></template>
    </a-modal>
    <!-- 编辑弹窗 -->
    <a-modal
      v-model="EditVisible"
      title="编辑考生"
      okText="确认"
      @ok="edititem()"
      width="587px"
    >
      <div class="all_content_box">
        <div class="line-item">
          <span class="all_left_name">姓名</span>
          <a-input
            class="right"
            v-model="formObJ.name"
            placeholder="请输入考生姓名"
          />
        </div>
        <div class="line-item">
          <span class="all_left_name">身份证号：</span>
          <a-input
            class="right"
            v-model="formObJ.idcard"
            placeholder="请输入考生身份证号"
          />
        </div>
        <div class="line-item">
          <span class="all_left_name">照片：</span>
          <template>
            
            <a-upload
              list-type="picture-card"
              :showUploadList="false"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <img
                width="300"
                v-if="formObJ.certifiMobile"
                :src="formObJ.certifiMobile"
                alt="avatar"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
          </template>
        </div>
        <div class="line-item">
          <span class="all_left_name">考试日期：</span>
          <a-date-picker
            disabled
            v-model="formObJ.examDate"
            class="right"
            placeholder="请选择考试日期"
          />
        </div>
        <div class="line-item">
          <span class="all_left_name">考试场次：</span>
          <a-input
            disabled
            class="right"
            v-model="formObJ.examDateTime"
            placeholder="请选择考试场次"
          />
        </div>
        <div class="line-item">
          <span class="all_left_name">课程名称：</span>
          <a-input
            disabled
            v-model="formObJ.examDateTime"
            class="right"
            placeholder="请输入课程名称"
          />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "考生姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "身份证号",
    align: "center",
    dataIndex: "idcard",
  },
  {
    title: "考试名称",
    align: "center",
    dataIndex: "examName",
  },
  {
    title: "考试日期",
    width: 200,
    align: "center",
    dataIndex: "examDate",
  },
  {
    title: "考试场次",
    align: "center",
    width: 240,
    dataIndex: "examDateTime",
  },
  {
    title: "考试成绩",
    align: "center",
    dataIndex: "totalScore",
  },
  
  {
    title: "操作",
    align: "center",
    dataIndex: "code7",
    scopedSlots: { customRender: "operation" },
  },
];
const ExamColumns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "考试时间",
    align: "center",
    dataIndex: "examDate",
  },
  {
    title: "考试场次",
    align: "center",
    dataIndex: "examDateTime",
  },
  {
    title: "人数",
    align: "center",
    dataIndex: "signupNum",
  },
];
const EchoExamColumns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "考试场次",
    align: "center",
    dataIndex: "examDate",
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      recordId: null, // 主表id

      columns, // 表头
      ExamColumns,
      EchoExamColumns,

      PreventLoad:false,  // 防抖

      // 大列表
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数

      // 弹窗内
      testData: [], // 考试场次数据
      totals: 0, // 总数量
      pageNumbers: 1, //页码
      pageSizes: 10, // 页数
      totals: 0, // 总数量

      ExamVisible: false, // 关联考试弹窗
      EchoExamVisible: false, //回显考试场次
      EditVisible: false, // 编辑弹窗
      newVisible: false, // 新建弹窗

      isChecked: false,

      selectedRowKey: [],
      selectedRowKeys: [],
      configId: null, //课程id
      // 考生信息
      formObJ: {
        idcard: "", //身份证号
        name: "", //姓名
        certifNumber: "",
        consignee: "",
        consigneeAddress: "",
        consigneeMobile: "",
        deleted: 0,
        examineeId: 0,
        generatTime: "",
        logisticsCode: "",
        logisticsCompany: "",
        recordId: 0,
        shipStatus: true,
        status: true,
        subId: 0,
        tripaCertifNumber: "",
        userId: 0,
      },
      recordId: null, // 返回值传输的id

      strings: null, // 考试场次名称
    };
  },
  // 事件处理器
  methods: {
    // 文件上传
    customRequest(fileData) {
      this.loading = true;
      const bucket = 'user/signup/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.formObJ.certifiMobile = url;
          this.$forceUpdate()
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
    // 大表格-选择考生。分页
    onPage(e) {
      console.log("大表格-选择考生。分页");
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getform();
    },
    // 弹框内分页
    onPages(e) {
      console.log("选择场次。弹窗内分页");
      this.pageNumbers = e.current;
      this.pageSizes = e.pageSize;
      // 选择场次。弹窗内分页更改获取新的列表
      if (!this.recordId) {
        this.gettestData();
      }
    },
    // 学习人数详情页
    goLearnPage() {
      this.$router.push({ path: "/admin/Certificate/Learner" });
    },
    // 获证人数详情页
    goObtainPage() {
      this.$router.push({ path: "/admin/Search/UserDetail" });
    },
    // 单选选择考生
    select(selectedRowKey) {
      this.selectedRowKey = selectedRowKey;
      if (this.selectedRowKey.length == this.tableData.length) {
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
    },
    // 单选选择考生
    selectAll(e) {
      // console.log(this.selectedRowKey, e);
    },
    //全选
    onChange(e) {
      if (e.target.checked == true) {
        this.isChecked = true;
        let id = this.tableData.map((item) => {
          return item.subId;
        });
        this.selectedRowKey = id;
      } else {
        this.isChecked = false;
        this.selectedRowKey = [];
      }
      // console.log(this.selectedRowKey);
    },
    // 弹窗内操作
    selects(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 表格内
    selectAlls(selectedRows) {
      this.selectedRowKeys = selectedRows;
      // console.log(selectedRows);
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
      }
    },

    // 证书生成
    goPage() {
      this.$router.push({
        path: "/admin/Certificate/GenerateCertificate",
        query: { recordId: this.recordId },
      });
    },
    // 校验图片
    beforeUpload(file) {
       if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg)
        return false
      }
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png" || file.type === 'image/jpg';
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 8;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },
    // 编辑弹窗
    editshow(e) {
      this.state == 2;
      this.EditVisible = true;
      // 取消双向绑定
      for (const key in e) {
        this.formObJ[key] = e[key];
      }
    },
    // 新建/编辑考生
    edititem() {
      let url = "";
      let method = "";
      let oBJ = {
        certifiMobile: "",
        idcard: "",
        name: "",
        signupUserId: "",
      };
      for (const key in oBJ) {
        oBJ[key] = this.formObJ[key];
      }
      console.log(oBJ);
      // // 新增
      // if (this.state == 1) {
      //   url = "/hxclass-management/institution/insert";
      //   method = "POST";
      // }
      // // 编辑
      // else if (this.state == 2) {
      url = "/hxclass-management/exam/signup/signup/user/edit";
      method = "PUT";
      // }
      this.$ajax({
        url,
        method,
        params: oBJ,
      }).then((res) => {
        if (res.code == 200) {
          this.label = "";
          this.getform();
          if (this.state == 1) {
            this.$message.success("操作成功");
          } else {
            this.$message.success(res.message);
          }
        }
        this.EditVisible = false;
      });
    },
    // 获取场次下考生的数据,大表格
    getData() {
      this.loading = true;
      let timeIds = this.selectedRowKeys.join(",");
      if (!timeIds) {
        this.$message.warning("请选择场次");
        return;
      }
      let string = "";
      // 取出考试场次
      for (let i = 0; i < this.selectedRowKeys.length; i++) {
        for (let j = 0; j < this.testData.length; j++) {
          if (this.selectedRowKeys[i] == this.testData[j].timeId) {
            string += this.testData[j].examDateTime;
            // console.log(string);
          }
        }
      }
      this.strings = string;
      this.$ajax({
        url: "/hxclass-management/exam/certif/time/examinee-list",
        method: "get",
        params: {
          current: this.pageNumber,
          size: this.pageSize,
          timeId: timeIds,
        },
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            this.tableData = res.data.records;
            this.total = res.data.total;
            // 没有新增走新增 ,不是新增是分页时不走
            // if (!recordId) {
              let arr = [];
              res.data.records.forEach((element) => {
                arr.push({
                  userId: element.signupUserId,
                  examineeId: element.examineeId,
                });
              });
              arr = JSON.stringify(arr);
              this.addExaminee(arr);
            // }
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 获取考试场次数据，选择场次。弹窗内分页更改获取新的列表
    gettestData() {
      this.$ajax({
        url: "/hxclass-management/exam/certif/time-list",
        method: "get",
        params: {
          current: this.pageNumbers,
          size: this.pageSizes,
          configId: this.configId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.testData = res.data.records;
          this.totals = res.data.total;
        }
      });
    },
    // 回显考试场次数据
    getEchoSessions() {
      this.$ajax({
        url: "/hxclass-management/exam/certif/generat-time",
        method: "get",
        params: {
          recordId: this.recordId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.testData = res.data;
          this.totals = res.data.length;
          this.selectedRowKeys = [];
          res.data.forEach((element) => {
            this.selectedRowKeys.push(element.timeId);
          });
        }
      });
    },
    // 打开新增考生的弹窗
    onAddStudent() {
      let timeIds = this.selectedRowKeys.join(",");
      if (!timeIds) {
        this.$message.warning("请先选择场次");
        return;
      }
      this.newVisible = true;
    },
    // 获取场次下某个考生的数据并添加
    getExaminee() {
      // 查询
      let timeIds = this.selectedRowKeys.join(",");
      let cemid = null;
      if (!this.formObJ.name) {
        this.$message.warning("请填写姓名");
        return;
      }
      if (!this.formObJ.idcard) {
        this.$message.warning("请填写身份证号");
        return;
      }
      this.PreventLoad=true
      this.$ajax({
        url: "/hxclass-management/exam/certif/query/examinee-exist",
        method: "get",
        params: {
          idcard: this.formObJ.idcard,
          name: this.formObJ.name,
          timeid: timeIds,
        },
      }).then((res) => {
        this.PreventLoad=false
        if (res.code == 200 && res.success) {
          cemid = res.data.examineeId;
          this.onAddExamStudent(cemid);
          this.formObJ.idcard = "";
          this.formObJ.name = "";
        } else {
          this.$message.error(res.message);
          // this.newVisible = false;
        }
      });
    },
    // 新增考生
    onAddExamStudent(cemid) {
      this.$ajax({
        url: "/hxclass-management/exam/certif/sub/save",
        method: "post",
        params: {
          recordId: this.recordId,
          examineeId: cemid,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.getform();
          this.newVisible = false;
        } else {
          this.$message.error(res.message);
          this.newVisible = false;
        }
      });
    },
    // 列表选择完—>考生数据添加
    addExaminee(e) {
      let timeIds = this.selectedRowKeys.join(",");
      this.$ajax({
        url: "/hxclass-management/exam/certif/save",
        method: "POST",
        params: {
          examineeJson: e,
          areaIds: timeIds,
          certifTempId: this.testData[0].certifTempId,
          examConfigId: this.configId,
        },
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            this.$message.success(res.message);
            this.recordId = res.data;
            this.getform();
            this.ExamVisible = false;
          } else {
            this.$message.error(res.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 批量删除
    deleteitem() {
      let timeIds = this.selectedRowKey.join(",");
      // console.log(timeIds);
      if (!this.selectedRowKey.length) {
        this.$message.warning("请选择考生");
        return;
      }
      let _this = this;
      this.deleteExaminee(timeIds);
    },
    // 删除考生
    deleteExaminee(e) {
      let _this = this;
      this.$confirm({
        title: "确定删除?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/exam/certif/sub/del?subId=" + e,
              method: "PUT",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.getform();
              } else {
                _this.$message.error("操作失败");
              }
            });
        },
        onCancel() {},
      });
    },

    // 获取已保存的信息
    getform() {
      this.$ajax({
        url: "/hxclass-management/exam/certif/sub/list",
        method: "get",
        params: {
          current: this.pageNumber,
          size: this.pageSize,
          status: null,
          recordId: this.recordId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.configId = this.$route.query.configId;
    this.recordId = this.$route.query.recordId;
    if (this.recordId != null) {
      this.getform();
      this.getEchoSessions();
    } else {
      this.gettestData();
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
@import "./step.less";
a.crumbs_item {
  color: #333;
}
/deep/.ant-upload-picture-card-wrapper {
  width: auto;
}
.all_content_box {
  text-align: center;
  .line-item {
    margin-bottom: 20px;
    display: flex;
    .all_left_name {
      width: 90px;
      text-align: right;
      margin-right: 20px;
    }
    img {
      max-width: 300px;
    }
  }
  .right {
    flex: 1;
    height: 40px;
    /deep/.ant-select-selection--single {
      height: 40px;
    }
    /deep/.ant-select-selection--single .ant-select-selection__rendered {
      height: 40px;
    }
    /deep/.ant-input {
      height: 40px;
    }
  }
  textarea.ant-input {
    flex: 1;
  }
}
.modalDiv {
  > p {
    font-weight: 500;
    color: #333333;
  }
  .code-item {
    > span {
      display: inline-block;
      min-width: 100px;
      margin: 5px 0;
      margin-right: 15px;
    }
  }
}
</style>
